import React from "react"
import { Link } from "gatsby-plugin-react-intl"
import { StyledHeader } from "./Header.styled"
import LangSwitcher from "../LangSwitcher"
import MenuTrigger from "../MenuTrigger"
import Navigation from "../Navigation"
import logo from "../../assets/images/logo.svg"

const Header = ({ fixed }) => {
  const [open, setOpen] = React.useState(false)

  React.useEffect(() => {
    open ? document.documentElement.style.overflowY = 'hidden' : document.documentElement.removeAttribute('style')
  }, [open])

  return (
    <StyledHeader fixed={fixed}>
      <div className="container">
        <Link to="/" className="logo">
          <img src={logo} alt="UTS Czapnik - CNC machining since 1993" />
        </Link>
        <Navigation open={open} setOpen={setOpen}/>
        <LangSwitcher/>
        <MenuTrigger open={open} setOpen={setOpen}></MenuTrigger>
      </div>
    </StyledHeader>
  )
}

export default Header
