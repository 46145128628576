import styled from "@emotion/styled"

export const StyledLangSwitcher = styled.div`
  margin: 0 20px 0 auto;

  button {
    padding: 5px;
    background: none;
    border: none;
    outline: none;
    color: #414141;
    text-transform: uppercase;
    text-decoration: none;
    transition: color .3s;
    cursor: pointer;

    &:hover {
      color: #c9514a;
    }

    &.active {
      color: #c9514a;
    }
  }

  @media (min-width: 992px) {
    margin: 0 -5px;
  }
`
