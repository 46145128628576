import styled from "@emotion/styled"

export const StyledNavigation = styled.nav`

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      display: block;
      position: relative;
      margin: 0;
    }
  }

  @media (max-width: 991px) {
    display: flex;
    position: fixed;
    z-index: 1010;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    padding: 50px 0;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    background-color: #e8e8e8;
    transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(100%)'};
    transition: transform .5s;

    ul {
      max-height: calc(100vh - 100px);
      overflow-y: auto;
    }

    .submenu {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;

      > div {
        overflow: hidden;
        height: ${({ submenuContainerHeight }) => `${submenuContainerHeight}px`};
        transition: height .5s;
      }

      span {
        display: block;
        position: relative;
        padding-right: 25px;
        outline: none;

        &::after,
        &::before {
          content: '';
          display: block;
          position: absolute;
          top: 50%;
          width: 2px;
          height: 10px;
          margin-top: -5px;
          background-color: #414141;
          transition: background-color .3s;
        }

        &::after {
          right: 4px;
          transform: ${({ submenuOpen }) => submenuOpen ? 'rotate(-45deg)' : 'rotate(45deg)'};
        }

        &::before {
          right: 10px;
          transform: ${({ submenuOpen }) => submenuOpen ? 'rotate(45deg)' : 'rotate(-45deg)'};
        }

        &:hover {
          &::after,
          &::before {
            background-color: #c9514a;
          }
        }
      }

      a {
        font-size: 1rem;
        text-transform: none;
        font-weight: 400;
        padding-top: 0;
      }

      ul {
        max-height: auto;
        padding: 0;
      }
    }

    a,
    span {
      display: block;
      padding: 8px 15px;
      outline: none;
      text-align: center;
      color: #414141;
      text-transform: uppercase;
      text-decoration: none;
      font-size: 1.25rem;
      font-weight: 700;
      transition: color .3s;
      cursor: pointer;

      &:hover,
      &.active {
        color: #c9514a;
      }
    }
  }

  @media (min-width: 992px) {
    margin: 0 -.9375rem;

    ul {
      display: flex;
      flex-flow: row wrap;
    }

    a,
    span {
      display: inline-block;
      padding: .5rem .9375rem;
      outline: none;
      color: #414141;
      font-size: 1rem;
      line-height: 1.40625rem;
      font-weight: 700;
      text-align: center;
      text-decoration: none;
      text-transform: uppercase;
      transition: color .3s;
      cursor: pointer;

      &:hover,
      &.active {
        color: #c9514a;
      }
    }

    .submenu {
      > div {
        position: absolute;
        top: calc(100% + 20px);
        left: .5rem;
        overflow: hidden;
        height: ${({ submenuContainerHeight }) => `${submenuContainerHeight}px`};
        opacity: ${({ submenuOpen }) => submenuOpen ? 1 : 0};
        box-shadow: 0 5px 5px rgba(0, 0, 0, .1);
        transition: ${({ submenuOpen }) => submenuOpen ? 'opacity .5s' : 'height 0s .3s, opacity .3s'};

        ul {
          background-color: #e1e1e1;
          padding: .5rem 0;
        }

        a {
          white-space: nowrap;
          padding: .5rem 1.5rem;
          font-weight: normal;
          text-transform: none;
        }
      }
    }
  }
`
