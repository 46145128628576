import styled from "@emotion/styled"

export const StyledFooter = styled.footer`
  position: absolute;
  right: 0px;
  bottom: 0px;
  left: 0px;
  padding: 1.25rem 0;

  .copyright {
    margin: 0;
    font-size: .875rem;

    a {
      color: inherit;
      text-decoration: none;
      transition: opacity .3s;

      &:hover {
        opacity: .75;
      }
    }
  }
`
