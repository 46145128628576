import styled from"@emotion/styled"

export const StyledHeader = styled.header`
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;
  padding: 10px 0;
  background-color: #e8e8e8;
  transition: top .5s, padding .3s, box-shadow .3s;
  box-shadow: ${({ fixed }) => fixed ? '0 0 20px rgba(0, 0, 0, .1)' : 'none'};

  @media (min-width: 768px) {
    padding: 20px 0;
  }

  .container {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
  }

  .logo {
    img {
      display: block;
      width: auto;
      height: 24px;
      margin: 0;
      margin-right: 5px;

      @media (min-width: 768px) {
        height: 35px
      }
    }
  }
`
