import React from "react"
import scrollTo from "gatsby-plugin-smoothscroll"
import Header from "../Header"
import Footer from "../Footer"

const Layout = ({ children, home }) => {
  const [scroll, setScroll] = React.useState(0)
  const [fixed, setFixed] = React.useState(false)

  React.useEffect(() => {
    const smoothScroll = event => {
      event.preventDefault()
      const target = event.currentTarget.hash
      scrollTo(target)
    }

    const fixedHeader = () => {
      const header = document.querySelector('header')
      const headerHeight = header.offsetHeight
      const windowScrollTop = window.scrollY

      windowScrollTop > headerHeight ? setFixed(true) : setFixed(false)

      if (Math.abs(scroll - windowScrollTop) > 5) {
        if (windowScrollTop > scroll && windowScrollTop >= headerHeight) {
          header.style.top = `-${headerHeight}px`
        } else {
          header.removeAttribute('style')
        }
        setScroll(windowScrollTop)
      }
    }

    window.addEventListener('scroll', fixedHeader);
    document.querySelectorAll('.smooth-scroll').forEach(element => {
      element.addEventListener('click', smoothScroll);
    })

    return () => {
      window.removeEventListener('scroll', fixedHeader);
      document.querySelectorAll('.smooth-scrll').forEach(element => {
        element.removeEventListener('click', smoothScroll);
      })
    }
  }, [scroll])

  return (
    <>
      <div id="top"></div>
      <Header home={home} fixed={fixed} />
      <main>{children}</main>
      <Footer />
    </>
  )
}

export default Layout
