import React from "react"
import { StyledFooter } from "./Footer.styled"

const Footer = () => (
  <StyledFooter>
    <div className="container">
      <p className="copyright">
        © UTSCzapnik { new Date().getFullYear() }. All rights reserved. <br/>
        Created by <a href="https://www.codemag.pl/">Codemag</a>.
      </p>
    </div>
  </StyledFooter>
)

export default Footer
