import React from "react"
import { IntlContextConsumer, changeLocale } from "gatsby-plugin-react-intl"
import { StyledLangSwitcher } from "./LangSwitcher.styled"

const LangSwitcher = () => (
  <StyledLangSwitcher>
    <IntlContextConsumer>
      {({ languages, language: currentLanguage }) =>
        languages.map(language => (
          <button
            key={language}
            className={currentLanguage === language ? 'active' : null}
            onClick={() => changeLocale(language)}
          >
            {language}
          </button>
        ))
      }
    </IntlContextConsumer>
  </StyledLangSwitcher>
)

export default LangSwitcher
