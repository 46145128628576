import React from "react"
import slugify from "slugify"
import { useStaticQuery, graphql } from "gatsby"
import { bool } from "prop-types"
import { injectIntl, Link } from "gatsby-plugin-react-intl"
import { StyledNavigation } from "./Navigation.styled"

const Navigation = ({ open, intl }) => {
  const data = useStaticQuery(graphql`
    query {
      servicesPL: allCockpitServices(filter: { lang: { eq: "pl" } }) {
        edges {
          node {
            cockpitId
            title {
              value
            }
          }
        }
      }

      servicesEN: allCockpitServices(filter: { lang: { eq: "en" } }) {
        edges {
          node {
            title {
              value
            }
          }
        }
      }
    }
  `)

  const [submenuOpen, setSubmenuOpen] = React.useState(false)
  const [submenuContainerHeight, setSubmenuContainerHeight] = React.useState(0)

  const submenuContainer = React.useRef()
  const submenu = React.useRef()

  React.useEffect(() => {
    window.addEventListener('click', hideSumbenu)
    window.addEventListener('scroll', hideSumbenu)

    return () => {
      window.removeEventListener('click', hideSumbenu)
      window.removeEventListener('scroll', hideSumbenu)
    }
  })

  const hideSumbenu = () => {
    setSubmenuOpen(false)
    setSubmenuContainerHeight(0)
  }

  const toggleSubmenu = e => {
    e.stopPropagation()

    const height = submenu.current.offsetHeight;
    if (submenuOpen) {
      setSubmenuOpen(false)
      setSubmenuContainerHeight(0)
    } else {
      setSubmenuOpen(true)
      setSubmenuContainerHeight(height)
    }
  }

  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      toggleSubmenu()
    }
  }

  const services = data.servicesPL.edges.map(
    ({ node }, i) => ({
      id: node.cockpitId,
      pl: node.title.value,
      en: data.servicesEN.edges[i].node.title.value,
      slug: slugify(node.title.value, { lower: true })
    })
  )

  return (
    <StyledNavigation open={open} submenuOpen={submenuOpen} submenuContainerHeight={submenuContainerHeight}>
      <ul>
        <li><Link to="/" activeClassName="active">{intl.formatMessage({ id: "homepage" })}</Link></li>
        <li><Link to="/o-firmie/" activeClassName="active">{intl.formatMessage({ id: "aboutpage" })}</Link></li>
        <li className="submenu">
          <span onClick={toggleSubmenu} onKeyPress={handleKeyPress} role="button" tabIndex="0">{intl.formatMessage({ id: "services" })}</span>
          <div ref={submenuContainer}>
            <ul ref={submenu}>
              {
                services.map(({ id, pl, en, slug }) => <li key={id}><Link to={`/${slug}/`} onClick={toggleSubmenu}>{intl.locale === 'pl' ? pl : en}</Link></li>)
              }
            </ul>
          </div>
        </li>
        <li><Link to="/realizacje/" activeClassName="active">{intl.formatMessage({ id: "realizationspage" })}</Link></li>
        <li><Link to="/kontakt/" activeClassName="active">{intl.formatMessage({ id: "contactPage" })}</Link></li>
      </ul>
    </StyledNavigation>
  )
}

Navigation.propTypes = {
  open: bool.isRequired,
}

export default injectIntl(Navigation)
