import React from "react"
import { bool, func } from "prop-types"
import { StyledMenuTrigger } from "./MenuTrigger.styled"

const MenuTrigger = ({ open, setOpen }) => {
  const handleClick = () => {
    setOpen(!open)
  }

  return (
    <StyledMenuTrigger open={open} onClick={handleClick}>
      <span className="line"></span>
      <span className="line"></span>
      <span className="line"></span>
    </StyledMenuTrigger>
  )
}

MenuTrigger.propTypes = {
  open: bool.isRequired,
  setOpen: func.isRequired,
}

export default MenuTrigger
